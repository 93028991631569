<template>
  <user-contacts-section
    v-if="talentUser"
    :key="updateKey"
    :user="talentUser"
    :user-id="talentId"
    :socials="allUserSocials"
  ></user-contacts-section>
</template>
<script>
import { UserContactsSection } from "@/components/contact/sections";
export default {
  name: "ContactCommunicationsPage",
  components: {
    UserContactsSection,
  },
  data() {
    return {
      updateKey: 0,
    };
  },
  metaInfo() {
    return {
      title: `Карточка контакта / Контакты`,
    };
  },
  computed: {
    talentUser() {
      return this.$store.state.talent.users[this.$route.params.id];
    },
    talentId() {
      return +this.$route.params.id;
    },
    socials() {
      return this.$store.state.contact.socials;
    },
    allUserSocials() {
      const { list, telegramAccounts, kaggleAccounts } = this.socials;
      return [...list, ...telegramAccounts, ...kaggleAccounts];
    },
  },
  activated() {
    this.updateKey += 1;
  },
};
</script>
